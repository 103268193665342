import { getVerticalScrollPosition } from '@/utils/scroll';

export function usePreventScroll() {
  const currentState = useState('currentPreventBodyState', () => false);
  const scrollPositionY = useState('scrollPositionY', () => 0);
  const bodyTop = useState('bodyTop', () => '');

  function preventScroll(state: boolean) {
    if (state) {
      const isActive = document.body.classList.contains('osk-body_prevent-scroll');

      if (isActive) return;

      scrollPositionY.value = getVerticalScrollPosition(window);
      bodyTop.value = document.body.style.top;

      document.body.classList.add('osk-body_prevent-scroll');
      document.body.style.top = `-${scrollPositionY.value}px`;
    } else {
      document.body.classList.remove('osk-body_prevent-scroll');
      document.body.style.top = bodyTop.value;
      window.scrollTo(0, scrollPositionY.value);
    }
  }

  return {
    currentState,
    preventBodyScroll(state: boolean) {
      if (state !== currentState.value && (currentState || state === true)) {
        currentState.value = state;
        preventScroll(state);
      }
    },
  };
}
