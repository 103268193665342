type TDialog = {
  close?: () => void
} | null

export function useDialog() {
  const shouldMobileBannerHide = useState('shouldMobileBannerHide', () => false);
  const dialogsLevel = useState('dialogsLevel', () => 0);

  const currentDialog = useState<TDialog>('currentDialog', () => null);

  function closeOpenedDialog() {
    if (currentDialog.value?.close) {
      currentDialog.value.close();
    }
  }

  return {
    shouldMobileBannerHide,
    dialogsLevel,
    currentDialog,
    closeOpenedDialog,
  };
}
